  const setCookie = function (c_name, value, expire) { //expire 默认180天
    // var date=new Date()
    // date.setSeconds(date.getSeconds()+expire)
    expire = expire == undefined ? 180 : expire;
    document.cookie = c_name + "=" + escape(value) + "; expires=" + expire
  }
  const getCookie = function (c_name) {
    if (document.cookie.length > 0) {
      let c_start = document.cookie.indexOf(c_name + "=")
      if (c_start != -1) {
        c_start = c_start + c_name.length + 1
        let c_end = document.cookie.indexOf(";", c_start)
        if (c_end == -1) c_end = document.cookie.length
        return unescape(document.cookie.substring(c_start, c_end))
      }
    }
    return ""
  }
  const delCookie = function (c_name) {
    setCookie(c_name, "", -1)
  }

  // localStorage
  const localData = function (method, name, obj) {
    //   method：get获取，set存入或覆盖，clean清除
    //   name:名称
    //   obj:内容
    switch (method) {
      case 'get':
        if (localStorage.getItem(name + '_obj')) {
          return JSON.parse(localStorage.getItem(name + '_obj'));
        } else if (localStorage.getItem(name + '_str')) {
          return localStorage.getItem(name + '_str');
        } else {
          return null
        }
        case 'set':
          localData('clean', name);
          if (typeof obj == 'object') {
            localStorage.setItem(name + '_obj', JSON.stringify(obj));
          } else {
            localStorage.setItem(name + '_str', obj);
          }
          return true;
        case 'clean':
          localStorage.removeItem(name + '_obj');
          localStorage.removeItem(name + '_str');
          return true;
    }
  };

  // session
  const sessionData = function (method, name, obj) {
    //  method：get获取，set存入或覆盖，clean清除
    //  name:session
    //  obj:内容
    switch (method) {
      case 'get':
        if (sessionStorage.getItem(name + '_obj')) {
          return JSON.parse(sessionStorage.getItem(name + '_obj'));
        } else if (sessionStorage.getItem(name + '_str')) {
          return sessionStorage.getItem(name + '_str');
        } else {
          return null
        }
        case 'set':
          sessionData('clean', name);
          if (typeof obj == 'object') {
            sessionStorage.setItem(name + '_obj', JSON.stringify(obj));
          } else {
            sessionStorage.setItem(name + '_str', obj);
          }
          return true;
        case 'clean':
          sessionStorage.removeItem(name + '_obj');
          sessionStorage.removeItem(name + '_str');
          return true;
    }
  };

  export {
    setCookie,
    getCookie,
    delCookie,
    localData,
    sessionData,
  }