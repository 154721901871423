import Vue from 'vue'
import ws from '../../plugins/ws'

// const onLogin = function(params){
//   console.log('zhu hai san run login result', params);
// }
const state = {
  clearOldLogs: false, //是否清除旧指令记录
  groupId: '',//本次发送的指令分组ID
  commands: [], //本次发送的指令列表，新版本
  singleCmdResult: {},//发送单指令时的回传参数
  cmdResultRefresh: 0,//多指令回传消息时的监测指标量，界面通过观测此值刷新指令执行结果
  windowOnResize: 0,//监听页面大小变化事件
  lightCmds: [ //单灯指令列表
    { needArgs: false, needPswd: false, code: 'forceRead', opcode: 'dcs', name: '召测数据',lang: 'com.but.survey', img: 'forceRead' },
    { needArgs: true, needPswd: true, code: 'openSingle', opcode: 'dco', name: '开灯', lang: 'com.but.open.lamp', img: 'open' },
    { needArgs: true, needPswd: true, code: 'closeSingle', opcode: 'dco', name: '关灯', lang: 'com.but.close.lamp',img: 'close' },
    { needArgs: true, needPswd: true, code: 'handSingle', opcode: 'dco', name: '调光控制', lang: 'com.but.dimming',img: 'adjust' },
    // { needArgs: true, needPswd: true, code: 'handBright', opcode: 'dco', name: '仅调光', lang: 'com.but.bright.only',img: 'adjust' },
    // { needArgs: true, needPswd: true, code: 'handColor', opcode: 'dco', name: '仅调色温', lang: 'com.but.color.only',img: 'adjust' },
    { needArgs: false, needPswd: false, code: 'getclock', opcode: 'dcs', name: '查询时钟', lang: 'com.but.get.QueryClock',img: 'readClock' },
    { needArgs: false, needPswd: true, code: 'setclock', opcode: 'dcc', name: '校准时钟', lang: 'com.but.get.Calibrate',img: 'writeClock' },
    { needArgs: false, needPswd: false, code: 'getDayPlan1', opcode: 'dcs', name: '查询计时日表', lang: 'com.but.get.timing',img: 'readTime1' },
    { needArgs: true, needPswd: true, code: 'setDayPlan1', opcode: 'dcc', name: '设置计时日表', lang: 'com.but.set.timing',img: 'writeTime1' },
    { needArgs: false, needPswd: false, code: 'getDayPlan2', opcode: 'dcs', name: '查询准时日表', lang: 'com.but.get.ontime',img: 'readTime2' },
    { needArgs: true, needPswd: true, code: 'setDayPlan2', opcode: 'dcc', name: '设置准时日表', lang: 'com.but.set.ontime',img: 'writeTime2' },
    { needArgs: false, needPswd: false, code: 'getInfo', opcode: 'dcs', name: '查询设备信息', lang: 'com.but.get.deviceInfo',img: 'info' },
    { needArgs: false, needPswd: false, code: 'getSimInfo', opcode: 'dcs', name: '查询SIM卡信息', lang: 'com.but.get.sim',img: 'readSim' },
    { needArgs: false, needPswd: false, code: 'getGroup', opcode: 'dcs', name: '查询分组', lang: 'com.but.get.packets',img: 'readGroup' },
    { needArgs: true, needPswd: true, code: 'setGroup', opcode: 'dcc', name: '设置分组', lang: 'com.but.set.packets',img: 'setGroup' },
    { needArgs: true, needPswd: false, code: 'getPlanType', opcode: 'dcs', name: '查询日表类型', lang: 'com.but.get.tableType',img: 'readTimeType' },
    { needArgs: true, needPswd: true, code: 'setPlanType', opcode: 'dcc', name: '设置日表类型', lang: 'com.but.set.tableType',img: 'setTimeType' },
    { needArgs: false, needPswd: false, code: 'getLuxOpenValue', opcode: 'dcs', name: '查询光感开灯值', lang: 'com.but.get.sensitivity',img: 'setTimeType' },
    { needArgs: true, needPswd: true, code: 'setLuxOpenValue', opcode: 'dcc', name: '设置光感开灯值', lang: 'com.but.set.sensitivity',img: 'readTimeType' },
    { needArgs: false, needPswd: false, code: 'getSigFlag', opcode: 'dcs', name: '查询信号灯参数', lang: 'com.but.get.getSigFlag',img: 'setTimeType' },
    { needArgs: true, needPswd: true, code: 'setSigFlag', opcode: 'dcc', name: '设置信号灯参数', lang: 'com.but.get.setSigFlag',img: 'readTimeType' },
    { needArgs: false, needPswd: false, code: 'getUnionBright', opcode: 'dcs', name: '查询联动亮度', lang: 'com.but.get.linkageLight',img: 'getUnion' },
    { needArgs: true, needPswd: true, code: 'setUnionBright', opcode: 'dcc', name: '设置联动亮度', lang: 'com.but.set.linkageLight',img: 'setUnion' },
    { needArgs: false, needPswd: false, code: 'getUnionParam', opcode: 'dcs', name: '查询感应参数', lang: 'com.but.get.parameters',img: 'getParams' },
    { needArgs: true, needPswd: true, code: 'setUnionParam', opcode: 'dcc', name: '设置感应参数', lang: 'com.but.set.parameters',img: 'setParams' },
    { needArgs: true, needPswd: true, code: 'set433Args', opcode: 'dcc', name: '写入433参数', lang: 'com.but.433.args.set',img: 'setParams' },
    { needArgs: false, needPswd: false, code: 'get433Args', opcode: 'dcs', name: '查询433参数', lang: 'com.but.433.args.get',img: 'getParams' },
    { needArgs: true, needPswd: true, code: 'set433Plan', opcode: 'dcc', name: '写入433日表', lang: 'com.but.433.plan.set',img: 'setParams' },
    { needArgs: false, needPswd: false, code: 'get433Plan', opcode: 'dcs', name: '查询433日表', lang: 'com.but.433.plan.get',img: 'getParams' },
    { needArgs: true, needPswd: true, code: 'set433Id', opcode: 'dcc', name: '写入433ID', lang: 'com.but.433.id.set',img: 'setParams' },
    { needArgs: false, needPswd: false, code: 'get433Id', opcode: 'dcs', name: '查询433ID', lang: 'com.but.433.id.get',img: 'getParams' },
    { needArgs: true, needPswd: true, code: 'send433Adjust', opcode: 'dcc', name: '433调光', lang: 'com.but.433.adjust',img: 'setParams' },
    { needArgs: true, needPswd: true, code: 'setLatLng', opcode: 'dcc', name: '设置经纬度', lang: 'com.but.gis.set',img: 'setParams' },
    { needArgs: false, needPswd: false, code: 'getLatLng', opcode: 'dcs', name: '查询经纬度', lang: 'com.but.gis.get',img: 'setParams' },
    { needArgs: true, needPswd: true, code: 'setLatLngArgs', opcode: 'dcc', name: '设置经纬度参数', lang: 'com.but.gis.args.set',img: 'setParams' },
    { needArgs: true, needPswd: false, code: 'getLatLngArgs', opcode: 'dcs', name: '查询经纬度参数', lang: 'com.but.gis.args.get',img: 'setParams' },
    { needArgs: false, needPswd: false, code: 'getAlarmRange', opcode: 'dcs', name: '查询报警阈值', lang: 'com.but.get.alarmThreshold',img: 'getAlarm' },
    { needArgs: true, needPswd: true, code: 'setAlarmRange', opcode: 'dcc', name: '设置报警阈值', lang: 'com.but.set.alarmThreshold',img: 'setAlarm' },
    { needArgs: false, needPswd: false, code: 'getAlarmStatus', opcode: 'dcs', name: '查询报警状态', lang: 'com.but.get.alarmStatus',img: 'getAlarmStatus' },
    { needArgs: true, needPswd: true, code: 'setAlarmStatus', opcode: 'dcc', name: '设置报警状态', lang: 'com.but.set.alarmStatus',img: 'setAlarmStatus' },
    { needArgs: false, needPswd: false, code: 'getGyroLevel', opcode: 'dcs', name: '查询倾斜阈值', lang: 'com.but.get.tiltThreshold',img: 'getGyro' },
    { needArgs: true, needPswd: true, code: 'setGyroLevel', opcode: 'dcc', name: '设置倾斜阈值', lang: 'com.but.set.tiltThreshold',img: 'setGyro' },
    { needArgs: false, needPswd: false, code: 'getLeakageAlarmLevel', opcode: 'dcs', name: '查询漏电报警级别', lang: 'com.but.get.level.leakage',img: 'getAlarmStatus' },
    { needArgs: true, needPswd: true, code: 'setLeakageAlarmLevel', opcode: 'dcc', name: '设置漏电报警级别', lang: 'com.but.set.level.leakage',img: 'setAlarmStatus' },
    { needArgs: false, needPswd: false, code: 'getLeakageAlarmEnable', opcode: 'dcs', name: '查询漏电报警使能', lang: 'com.but.get.enable.leakage',img: 'getAlarmStatus' },
    { needArgs: true, needPswd: true, code: 'setLeakageAlarmEnable', opcode: 'dcc', name: '设置漏电报警使能', lang: 'com.but.set.enable.leakage',img: 'setAlarmStatus' },
    { needArgs: false, needPswd: false, code: 'getLeakageAlarmStatus', opcode: 'dcs', name: '查询漏电报警状态', lang: 'com.but.get.stata.leakage',img: 'getAlarmStatus' },
    { needArgs: true, needPswd: true, code: 'adjustChipBase', opcode: 'dck', name: '远程校准计量单元', lang: 'com.but.unit.remote',img: 'getAlarmStatus' },
    { needArgs: false, needPswd: false, code: 'getAdjustParams', opcode: 'dck', name: '查询计量单元校准系数', lang: 'com.but.get.unit.ratio',img: 'getAlarmStatus' },
    { needArgs: true, needPswd: true, code: 'adjustChipVoltage', opcode: 'dck', name: '设置电压校准系数', lang: 'com.but.set.v.coefficient',img: 'getAlarmStatus' },
    { needArgs: true, needPswd: true, code: 'adjustChipCurrent', opcode: 'dck', name: '设置电流校准系数', lang: 'com.but.set.i.coefficient',img: 'getAlarmStatus' },
    { needArgs: true, needPswd: true, code: 'adjustChipPower', opcode: 'dck', name: '设置功率校准系数', lang: 'com.but.set.p.coefficient',img: 'getAlarmStatus' },
    { needArgs: false, needPswd: false, code: 'getChipVolCurArgs', opcode: 'dcs', name: '查询计量芯片电压电流功率系数', lang: 'com.but.get.p.chipVolCur',img: 'readNet' },
    { needArgs: false, needPswd: false, code: 'getNetwork', opcode: 'dcs', name: '查询网络', lang: 'com.but.get.network',img: 'readNet' },
    { needArgs: true, needPswd: true, code: 'setNetwork', opcode: 'dck', name: '设置网络', lang: 'com.but.set.network',img: 'setNet' },
    { needArgs: false, needPswd: false, code: 'getAPN', opcode: 'dcs', name: '查询APN', lang: 'com.but.get.apn',img: 'readNet' },
    { needArgs: true, needPswd: true, code: 'setAPN', opcode: 'dck', name: '设置APN', lang: 'com.but.set.apn',img: 'setNet' },
    { needArgs: true, needPswd: true, code: 'update', opcode: 'dck', name: '在线升级', lang: 'com.but.set.upgrade',img: 'update' },
    { needArgs: false, needPswd: true, code: 'reset', opcode: 'dco', name: '重启设备', lang: 'com.but.set.reboot',img: 'reset' },
    { needArgs: false, needPswd: true, code: 'init', opcode: 'dck', name: '初始化', lang: 'com.but.initialization',img: 'init' },
    { needArgs: false, needPswd: true, code: 'clearEnergyNumber', opcode: 'dck', name: '清除能耗计数', lang: 'com.but.set.clean.energy',img: 'clearEnergyNumber' },
    { needArgs: false, needPswd: false, code: 'getSNID', opcode: 'dcs', name: '查询SNID', lang: 'com.but.get.snid',img: 'getAlarmStatus' },
    { needArgs: false, needPswd: true, code: 'resetSNID', opcode: 'dck', name: '复位SNID', lang: 'com.but.reset.snid',img: 'clearEnergyNumber' },
    { needArgs: false, needPswd: false, code: 'getRelayLevel', opcode: 'dcs', name: '查询中继级别', lang: 'com.but.get.level.relay',img: 'getAlarmStatus' },
    { needArgs: false, needPswd: true, code: 'setRelayLevel', opcode: 'dck', name: '设置中继级别', lang: 'com.but.set.level.relay',img: 'clearEnergyNumber' },
  ],
  powerCmds: [ //配电设备指令列表
    {code: 'forceRead', name: '召测', flag: 1},
    {code: 'getInfo', name: '查询设备信息', flag: 1},
    {code: 'setclock', name: '校准时钟', flag: 1},
    {code: 'getclock', name: '查询时钟', flag: 1},
    {code: 'reset', name: '复位', flag: 2},
    {code: 'init', name: '初始化', flag: 2},
    {code: 'setNetwork', name: '设置网络', flag: 0},
    {code: 'getNetwork', name: '查询网络', flag: 1},
    {code: 'getSimInfo', name: '查询SIM卡信息', flag: 1},
    {code: 'setCurrentRate', name: '设置电流互感器系数', flag: 0},
    {code: 'getCurrentRate', name: '查询电流互感器系数', flag: 0},
    {code: 'getWeekPlan', name: '查询周表', flag: 1},
    {code: 'setWeekPlan', name: '设置周表', flag: 0},
    {code: 'setTempPlan', name: '设置临时表', flag: 0},
    {code: 'getTempPlan', name: '查询临时表', flag: 0},
    {code: 'getYearPlan', name: '查询年表', flag: 0},
    {code: 'setYearPlan', name: '设置年表', flag: 0},
    {code: 'handControl', name: '手动控制', flag: 0},
    {code: 'getPlanType', name: '查询工作表类型', flag: 0},
    {code: 'setPlanType', name: '设置工作表类型', flag: 0},
    {code: 'getCtrlType', name: '读取控制状态', flag: 0},
    {code: 'update', name: 'OTA升级', flag: 0},
    {code: 'setDigitUnionParams', name: '设置数字联动参数', flag: 0},
    {code: 'getDigitUnionParams', name: '读取数字联动参数', flag: 0},
    {code: 'setAnalogUnionParams', name: '设置模拟联动参数', flag: 0},
    {code: 'getAnalogUnionParams', name: '读取模拟联动参数', flag: 0},
    {code: 'setAnalogAlarmRange', name: '设定模拟报警上下限', flag: 0},
    {code: 'getAnalogAlarmRange', name: '读取模拟报警上下限', flag: 0},
    {code: 'setLeakageChannel', name: '设定漏电流的支路号', flag: 0},
    {code: 'getLeakageChannel', name: '读取漏电流的支路号', flag: 0},
    {code: 'setCurrentLevel', name: '设定漏电流监测', flag: 0},
    {code: 'getCurrentLevel', name: '读取漏电流监测', flag: 0},
    {code: 'setCurrentError', name: '设定电流误差百分比', flag: 0},
    {code: 'getCurrentError', name: '读取电流误差百分比', flag: 0},
  ],
  monitorCmds: [
    { code: 'forceRead', name: '召测数据', opcode: 'dcs', needArgs: false, needPswd: false },
    { code: 'forceReadLight', name: '召测单灯', opcode: 'dcs', needArgs: false, needPswd: false },
    { code: 'getclock', name: '读取时钟', opcode: 'dcs', needArgs: false, needPswd: false },
    { code: 'setclock', name: '校准时钟', opcode: 'dcc', needArgs: false, needPswd: true },
    { code: 'getCmdCount', name: '查询指令数量', opcode: 'dcs', needArgs: false, needPswd: false },
    { code: 'clearCmds', name: '清除指令队列', opcode: 'dcs', needArgs: false, needPswd: false },
    { code: 'reset', name: '重启设备', opcode: 'dco', needArgs: false, needPswd: true },
    { code: 'init', name: '初始化', opcode: 'dck', needArgs: false, needPswd: true },

    { code: 'getWhite', name: '查询白名单', opcode: 'dcs', needArgs: false, needPswd: false },
    { code: 'setWhite', name: '设置白名单', opcode: 'dcc', needArgs: false, needPswd: true },

    //  { code: '更多', name: '更多', opcode: 'dcs', flag: 0, img: this.img.light.factory.power.devOps2.devOps8 },
    { code: 'update', name: 'OTA升级', opcode: 'dck', needArgs: true, needPswd: true },
    { code: 'setLoraWork', name: '设置Lora参数', opcode: 'dcc', needArgs: true, needPswd: true },
    { code: 'getLoraWork', name: '查询Lora参数', opcode: 'dcs', needArgs: false, needPswd: false },
    { code: 'getInfo', name: '设备信息', opcode: 'dcs', needArgs: false, needPswd: false },
    { code: 'getNetwork', name: '查询网络', opcode: 'dcs', needArgs: false, needPswd: false },
    { code: 'getSimInfo', name: 'SIM卡信息', opcode: 'dcs', needArgs: false, needPswd: false },
    { code: 'getLuxCtrlMode', name: '查询光控模式', opcode: 'dcs', needArgs: false, needPswd: false },
    { code: 'setLuxCtrlMode', name: '设置光控模式', opcode: 'dcc', needArgs: true, needPswd: true },
    { code: 'getLuxLevelMode', name: '查询光控挡位', opcode: 'dcs', needArgs: false, needPswd: false },
    { code: 'setLuxLevelMode', name: '设置光控挡位', opcode: 'dcc', needArgs: true, needPswd: true },
    { code: 'getLuxAutoMode', name: '查询恒照度参数', opcode: 'dcs', needArgs: false, needPswd: false },
    { code: 'setLuxAutoMode', name: '设置恒照度参数', opcode: 'dcc', needArgs: true, needPswd: true },
    { code: 'getLuxValue', name: '查询光控值', opcode: 'dcs', needArgs: false, needPswd: false },
  ],
  trans: [],//监听数据列表
  monitorDeviceId: '', //监听的顶级设备ID，为0表示未监听
  monitorDeviceConn: '',
  // devices: {}, //监听设备列表
  ledcmd: {
    code: '',
    no: '',
    id: '',
    content: {},
    paramId: 0,
    stationId: 0,
    time: 0,
  },
  ledState: {
    content: {},
    paramId: 0,
    stationId: 0,
    time: 0,
  },
  ledStateRefresh: 0,
  ledcmdRefresh: 0,
  luxes: {},
  luxDataRefreshed: 0,
  cmdAskModalRefresh: 0, //接收到窗口问询消息时，此值变化，界面通过监听此值弹出问询窗口
  cmdAskModalContent: [], //接收到最新的窗口问询消息列表
  luxParams: {}, //光控参数列表，专用于监测光照度值
  luxDataChanged: 0, //光控数据变化
  luxContent: { //光控缓存数据
    appType: 'road', //所属应用
    custId: 0, //所属用户
    's1p1': {}, //站点光照度数据，key格式：`s${stationId}p${paramId}`，value格式：按业务类型会有不同
  },
  zhuhaiSanrun: { //珠海三润相关业务逻辑
    account: { //账户信息
      has: false, //是否获取到账户信息，当珠海三润账户编辑时，推出当前登录用户，刷新当前用户信息，并以新用户登录
      id: 0,
      userName: '',
      userPswd: '',
      serverIp: '',
      serverPort: 0,
    },
    login: { //用户登录标识
      ok: false, //是否登录成功
      handle: 0, //用户登录后的标识
    },
    groups: [], //珠海三润平台的设备分组信息
    devices: [], //珠海三润平台的设备信息
    alarms: [], //珠海三润平台推送的报警通知
    alarmComing: 0, //来了报警通知，刷新此数据，在跟踪界面监听此值
    retryCount: 0, //重连次数
  }
}
const mutations = {
  setLuxContent: function(state, {appType, customerId, stationId, content}){
    if(state.luxContent.appType != appType || state.luxContent.custId != customerId){
      Vue.set(state, 'luxContent', {appType: appType, custId: customerId});
    }
    Vue.set(state.luxContent, `s${stationId}`, content);
    state.luxDataChanged++;
    // console.log('set lux content', state.luxContent);
  },
  setStationRealData: function(state, data){
    if(state.luxParams[data.paramId]){
      state.luxParams[data.paramId].ts = data.time;
      state.luxParams[data.paramId].content = data.data;
      state.luxDataChanged++;
      return;
    }
  },
  setLuxParams: function(state, params){
    state.luxParams = params;
    state.luxDataChanged = true;
  },
  setLuxAskContent: function(state, params){
    Vue.set(state, 'cmdAskModalContent', params)
  },
  dealAskCommandContent: function(state, params){
    for(let i = 0; i < state.cmdAskModalContent.length; i++){
      if(state.cmdAskModalContent[i].id == params.cmdId){
        state.cmdAskModalContent.splice(i, 1);
        state.cmdAskModalRefresh++;
        break;
      }
    }
    // console.log('cmd store deal lux data', params, state.cmdAskModalContent)
  },
  receiveCmdAskMessage: function(state, params){
    params.content.topic = params.topic;
    state.cmdAskModalContent.push(params.content);
    state.cmdAskModalRefresh++;
  },
  setFactoryLuxData: function(state, params){
    // console.log('cmd store get lux data', params)
    state.luxes[params.paramId] = params.data;
    state.luxDataRefreshed ++;
    if(state.luxDataRefreshed > 10000000){
      state.luxDataRefreshed = 0;
    }
  },
  ledPlayStateReceived: function(state, params){
    state.ledState.content = params.content;
    state.ledState.paramId = params.paramId;
    state.ledState.stationId = params.stationId;
    state.ledState.time = params.time;
    state.ledStateRefresh++;
  },
  ledCmdClear: function(state){
    state.commands.splice(0, state.commands.length);
  },
  ledCmdSend: function(state, params){
    state.commands.push(params);
    state.cmdResultRefresh = 0;
  },
  ledCmdReceived: function(state, params){
    let cmds = state.commands.filter(p => p.cmdId == params.cmdId);
    if(cmds.length == 0)return;
    let cmd = cmds[0];
    Vue.set(cmd, 'content', params.content);
    Vue.set(cmd, 'time', params.time);
    Vue.set(cmd, 'status', 9);
    // state.ledcmd.content = params.content;
    // state.ledcmd.paramId = params.paramId;
    // state.ledcmd.stationId = params.stationId;
    // state.ledcmd.time = params.time;
    state.cmdResultRefresh++;
    // console.log('rec led cmd result', params, cmd)
  },
  initOldLogStatus: function(state){
    state.clearOldLogs = localStorage.getItem('clearOldLogs') == 'true';
  },
  setOldLogStatus: function(state, clear){
    state.clearOldLogs = clear;
    localStorage.setItem('clearOldLogs', clear);
  },
  setTransData: function(state, data){
    if(data.code == state.monitorDeviceId || data.conn == state.monitorDeviceConn){
      state.trans.push({ts: data.ts, send: data.send, code: data.code, bytes: data.bytes});
    }
  },
  clearTransData: function(state){
    Vue.set(state, 'trans', []);
  },
  enableMonitorDeviceByConn: function(state, conn){ //启用设备数据收发监听
    Vue.set(state, 'trans', []);
    state.monitorDeviceConn = conn;
    ws.sendCmd('subconn', {conn: conn});
  },
  disableMonitorDeviceByConn: function(state){ //禁用设备数据收发监听
    Vue.set(state, 'trans', []);
    state.monitorDeviceConn = '';
    ws.sendCmd('unsubconn', {});
  },
  enableMonitorDevices: function(state, code){ //启用设备数据收发监听
    if(code.length == 0)return;
    state.monitorDeviceId = code;
    ws.sendCmd('sub', {code: code});
  },
  disableMonitorDevices: function(state){ //禁用设备数据收发监听
    Vue.set(state, 'trans', []);
    state.monitorDeviceId = '';
    ws.sendCmd('unsub', {});
  },
  enableQrCode: function(state,data){ //启用监听二维码登录
    Vue.set(state, 'trans', []);
    ws.sendCmd('qrcode', {code: data});
  },
  pushCommands: function(state, cmds){
    //status: 0、等待发送，1、等待回复，2、指令超时，3、执行失败，4、执行中，5、失败重发，9、执行成功
    state.commands.splice(0, state.commands.length);
    for(let c of cmds){
      c.cmdId = '';
      c.status = 0;
      c.content = {};
      c.result = '';
      state.commands.push(c);
    }
    state.cmdResultRefresh = 0;
  },
  lightCommandSent: function(state, cmds){
    state.commands.splice(0, state.commands.length);
    for(let cmd of cmds){
      cmd.result = cmd.message;
      cmd.content = {};
      state.commands.push(cmd);
    }
  },
  setCommandResultById: function(state, data){
    state.groupId = data.groupId;
    let change = 0;
    for(let item of data.list){
      let ds = state.commands.filter(it => it.id == item.itemId);
      for(let d of ds){
        Vue.set(d, 'cmdId', item.cmdId);
        Vue.set(d, 'status', item.status);
        Vue.set(d, 'result', item.message);
        change++;
      }
    }
    if(change > 0){
      state.cmdResultRefresh++;
    }
  },
  setCommandResultByCmd: function(state, data){
    let ds = state.commands.filter(it => it.cmdId == data.cmdId);
    if(ds.length > 0){
      ds[0].status = data.status;
      ds[0].content = data.content;
      ds[0].result = data.message;
      state.cmdResultRefresh++;
    }
  },
  clearHistory: function(state){
    Vue.set(state, 'commands', []);
  },
  setWindowOnResize: function(state){
    state.windowOnResize++;
  },
  // resetCommand: function(state, cmd){
  //   state
  // }
  zhuhaiSanrunAccountGot: function(state, params){
    if(!params.account || !params.account.id)return;
    state.zhuhaiSanrun.account.has = true;
    state.zhuhaiSanrun.account.id = params.account.id;
    state.zhuhaiSanrun.account.userName = params.account.userName;
    state.zhuhaiSanrun.account.userPswd = params.account.userPswd;
    state.zhuhaiSanrun.account.serverIp = params.account.serverIp;
    state.zhuhaiSanrun.account.serverPort = params.account.serverPort;
    Vue.set(state.zhuhaiSanrun, 'devices', params.list);
    // console.log('zhu hai san run account result', params, state.zhuhaiSanrun);
    window.vue.dhweb.setWebsocketPort({dataWsPort: state.zhuhaiSanrun.account.serverPort,mediaWsPort: state.zhuhaiSanrun.account.serverPort});
    setTimeout(() => {
      // console.log('start login', state.zhuhaiSanrun.account)
      window.vue.dhweb.login(state.zhuhaiSanrun.account.userName, state.zhuhaiSanrun.account.userPswd, state.zhuhaiSanrun.account.serverIp);
      // window.vue.$store.commit('cmd/checkLoginState', 1);
    }, 1000);
  },
  checkLoginState: function(state, times){
    // console.log('zhu hai san run login check', state.zhuhaiSanrun.login.handle, times++);
    if(state.zhuhaiSanrun.login.handle > 0)return;
    if(times > 10){
      window.vue.dhweb.login(state.zhuhaiSanrun.userName, state.zhuhaiSanrun.userPswd, state.zhuhaiSanrun.serverIp);
      times = 1;
    }
    setTimeout((ts) => {
      window.vue.$store.commit('cmd/checkLoginState', ts)
    }, 1000, times);
  },
  zhuhaiSanrunLogin: function(state, params){
    // console.log('zhu hai san run login result', params);
    if(params.error == 'success'){
      state.zhuhaiSanrun.login.handle = params.params.loginHandle;
    }
  },
  zhuhaiSanrunAlarmServerClosed: function(state){
    state.zhuhaiSanrun.retryCount++;
    state.zhuhaiSanrun.login.handle = 0;
    if(state.zhuhaiSanrun.retryCount >= 10){
      // console.log('zhu hai san run server closed, give up, if u want retry, please press F5 to refresh current page');
      return;
    }
    // console.log('zhu hai san run server closed, retry after 10 seconds ...', state.zhuhaiSanrun.retryCount);
    setTimeout(() => {
      // console.log('start login', state.zhuhaiSanrun.account)
      window.vue.dhweb.login(state.zhuhaiSanrun.account.userName, state.zhuhaiSanrun.account.userPswd, state.zhuhaiSanrun.account.serverIp);
      // window.vue.$store.commit('cmd/checkLoginState', 1);
    }, 10000);
  },
  zhuhaiSanrunGroupList: function(state, params){
    // console.log('zhu hai san run group result', params);
    if(params.error != 'success')return;
    for(let g of params.params.list){
      let gs = state.zhuhaiSanrun.groups.filter(p => p.groupId == g.groupId);
      if(gs.length == 0){
        state.zhuhaiSanrun.groups.push(g);
      }else{
        gs[0].groupName = g.groupName;
        gs[0].groupType = g.groupType;
        gs[0].parentId = g.parentId;
      }
    }
  },
  zhuhaiSanrunDeviceList: function(state, params){
    for(let g of params.params.list){
      let gs = state.zhuhaiSanrun.devices.filter(p => p.code == g.deviceId);
      // console.log('zhu hai san run device result', gs);
      if(gs.length == 0)return;
      gs[0].online = true;
      gs[0].alarm = false;
      gs[0].running = false;
    }
  },
  zhuhaiSanrunNotify: function(state, params){
    // console.log('zhu hai san run notify result', params);
    if(params.params.code == 'DeviceStatus'){ //报警设备状态通知
      if(params.params.action == 'Start'){ //正在呼叫
        let gs = state.zhuhaiSanrun.devices.filter(p => p.code == params.params.deviceId);
        if(gs.length == 0)return;
        gs[0].alarm = true;
        gs[0].running = false;
        // state.zhuhaiSanrun.alarms.push({deviceId: params.params.deviceId, status: 1, receiveTime: new Date()});
        state.zhuhaiSanrun.alarmComing++;
      }else if(params.params.action == 'Dealing'){ //正在处理报警，即已接听
        let gs = state.zhuhaiSanrun.devices.filter(p => p.code == params.params.deviceId);
        if(gs.length == 0)return;
        gs[0].alarm = true;
        gs[0].running = true;
        // let alarms = state.zhuhaiSanrun.alarms.filter(p => p.deviceId == params.params.deviceId);
        // if(alarms.length > 0){
        //   Vue.set(alarms[0], 'status', 2);
        // }else{
        //   state.zhuhaiSanrun.alarms.push({deviceId: params.params.deviceId, status: 2, dealTime: new Date()});
        // }
      }else if(params.params.action == 'Normal'){ //设备在线或已处理完报警
        let gs = state.zhuhaiSanrun.devices.filter(p => p.code == params.params.deviceId);
        if(gs.length == 0)return;
        gs[0].alarm = false;
        gs[0].running = false;
        // for(let i = 0; i < state.zhuhaiSanrun.alarms.length; i++){
        //   let alarm = state.zhuhaiSanrun.alarms[i];
        //   if(alarm.deviceId == params.params.deviceId){
        //     state.zhuhaiSanrun.alarms.splice(i, 1);
        //     //此处可向服务器发送保存报警记录
        //     break;
        //   }
        // }
      }
    }
    // console.log('zhu hai san run notify result', state.zhuhaiSanrun.alarms);
  },
}
// cmds: [...cmd]是指令cmd集合,cmd参数如下：
// cmd: {
//   id: '指令识别ID',
//   productCode: '产品类型编码',
//   productId: '产品ID',
//   topType: '通信设备类型编码',
//   topCode: '通信设备编码',
//   devType: '控制设备类型编码',
//   devCode: '控制设备编码',
//   code: '指令编码',
//   name: '指令名称',
//   args: {指令具体参数}
// }
const actions = {
  queryZhuhaiSanrun: function(context){
    Vue.$axios.post(`//${Vue.domains.trans}/station/Account/QueryDefaultZhuHaiSanRunAccount`, {}).then(res => {
      if(res.code == 0){
        context.commit('zhuhaiSanrunAccountGot', res.data);
      }
      // resolve(res);
    });
  },
  queryWaitCmds: function(context){
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/station/lux/QueryLuxAsk`, {}).then(res => {
        if(res.code == 0){
          context.commit('setLuxAskContent', res.data);
        }
        resolve(res);
      });
    });
  },
  dealWaitCmds: function(context, params){
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/station/lux/DealLuxAsk`, params).then(res => {
        context.commit('dealAskCommandContent', params);
        resolve(res);
      });
    });
  },
  sendFailed: function(context, ds){
    let cmds = []
    for(let c of context.state.commands){
      if((c.status == 2 || c.status == 3) && ds.indexOf(c.id) != -1){
        cmds.push({id: c.id, code: c.code, name: c.name, deviceId: c.deviceId, args: c.args});
        // c.status = 0;
        // c.result = '准备重发';
      }
    }
    console.log('send fialed 1', cmds, context.state.commands)
    if(cmds.length == 0){
      return new Promise(resolve => {
        resolve({code: -1});
      });
    }
    context.commit('pushCommands', cmds)
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/station/command/send`, {list: context.state.commands}).then(res => {
        context.commit('setCommandResultById', res.data);
        resolve(res);
      });
    });
  },
  sendCommands: function(context, cmds){
    context.commit('pushCommands', cmds)
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/station/command/send`, {list: context.state.commands}).then(res => {
        context.commit('setCommandResultById', res.data);
        resolve(res);
      });
    });
  },
  sendLightCommand: function(context, {code, name, args, ids}){
    // context.commit('pushCommands', cmds)
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/station/light/SendCommand`, {code, name, args, ids}).then(res => {
        // context.commit('setCommandResultById', res.data);
        context.commit('lightCommandSent', res.data.list);
        resolve(res);
      });
    });
  },
  sendGatewayCommand: function(context, {code, name, args, ids}){
    // context.commit('pushCommands', cmds)
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/station/light/SendGatewayCommand`, {code, name, args, ids}).then(res => {
        // context.commit('setCommandResultById', res.data);
        context.commit('lightCommandSent', res.data.list);
        resolve(res);
      });
    });
  },
}
const getters = {
  speakAlarm: function(state){
    return state.zhuhaiSanrun.devices.filter(p => p.alarm && !p.running).length > 0;
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};