<template>
  <!-- <Button v-if="canOp" :type="type" :size="size" @click="clickBtn"><slot></slot></Button> -->
  <!-- <MenuItem :name="name" v-if="canOp">
    <Icon :type="icon"></Icon>
    <span>{{title}}</span>
  </MenuItem> -->
<div v-if="canOp">{{title}}</div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'AuthMenuDiv',
  components:{
  },
  props: {
    opCode: {
      type: String,
      default: ''
    },
    opCodes: {
      type: Array,
      default(){return []}
    },
    name: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'ios-navigate'
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed:{
    ...mapState('auth', ['user', 'ops']),
    canOp: function(){
      if(this.user && this.user.isSuper)return true;
      if(this.ops && this.ops.indexOf(this.opCode) != -1)return true;
      if(this.ops && this.ops.filter(p => this.opCodes.indexOf(p) != -1).length > 0)return true;
      return false;
    },
  },
  data () {
    return {
      showModal: this.value,
    }
  },
  methods: {
    clickBtn () {
      this.$emit('click', {})
    },
  }
}
</script>
<style scoped>
</style>