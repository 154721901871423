<template>
  <Button v-if="canOp" :type="type" :long="long" :size="size" @click="clickBtn"><slot></slot></Button>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'AuthButton',
  components:{
  },
  props: {
    opCode: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'small'
    },
    long: {
      type: Boolean,
      default: false
    }
  },
  computed:{
    ...mapState('auth', ['user', 'ops']),
    canOp: function(){
      // if(this.opCode == 'slge'){
      //   console.log('can op', this.user, this.ops, this.opCode)
      // }
      if(this.user && this.user.isSuper)return true;
      if(this.ops && this.ops.indexOf(this.opCode) != -1)return true;
      return false;
    },
  },
  data () {
    return {
      showModal: this.value,
    }
  },
  methods: {
    clickBtn () {
      this.$emit('click', {})
    },
  }
}
</script>
<style scoped>
</style>