let protocol = "ws:"; //开发环境
if (process.env.NODE_ENV === "production") { //生产环境
  if (window.location.protocol == "https:") {
    protocol = "wss:";
  }
}
let ws = null;
let handlers = {};
let heart = null;
let vue = null;
let closeTime = 0;

function startWebsocket(v) {
  if (!vue) vue = v;
  // console.log('send heart', protocol)
  const wsUrl = `${protocol}//${vue.domains.trans}/data`;
  // const wsUrl = `wss://${vue.domains.trans}/xixun`;
  ws = new WebSocket(wsUrl);
  vue.$Notice.config({
    top: 100,
    duration: 10
  });

  ws.onopen = function () {
    if (closeTime != 0) {
      vue.$Notice.success({
        title: `连接已正常`,
        desc: '与服务器的连接已正常',
        duration: 3
      });
      closeTime = 0;
    }
    wsPlugin.sendHeart();
    heart = setInterval(wsPlugin.sendHeart, 30000)
  };
  ws.onmessage = function (evt) {
    const data = JSON.parse(evt.data);
    if (data.type == 'state') {
      // if(data.id == 682){
        // console.log("get station 682 msg:", data)
      // }
      vue.$store.commit(`group/setDeviceState`, data);
      if(data.isParam){
        window.eventBus.$emit('paramStateComing', {id: data.id, online: data.online, alarm: data.alarm, running: data.running});
      }
    } else if (data.type == 'trans') {
      // console.log('get trans msg', data)
      vue.$store.commit('cmd/setTransData', data);
    } else if (data.type == 'cmd') {
      // console.log('get cmd ws data', data, data.status, data.message);
      // if (data.cmdId > 0) {
      //   vue.$store.commit('cmd/setCommandResultByCmd', data);
      //   window.eventBus.$emit('paramCommandComing', data);
      // }
      if(data.cmdType == 51){
        window.eventBus.$emit('ledCommandComing', {cmdCode: data.cmdCode, commandId: data.commandId, content: data.content, message: data.message, status: data.status});
      }else if(data.cmdType == 52){
        window.eventBus.$emit('broadCommandComing', {cmdCode: data.cmdCode, commandId: data.commandId, content: data.content, message: data.message, status: data.status});
      }else{
        window.eventBus.$emit('paramCommandComing', {cmdCode: data.cmdCode, commandId: data.commandId, content: data.content, message: data.message, status: data.status});
      }
    } else if (data.type == 'qrcode') {
      window.eventBus.$emit('qrcodeDataComing', data);
    } else if (data.type == 'data') {
      // console.log('ws get data', data);
      if (vue.$store.state.auth.appType == '' || vue.$store.state.auth.appType == 'site') return;
      if (data.tag == 'faclux') {
        // console.log('ws get fac lux data', data);
        vue.$store.commit(`cmd/setFactoryLuxData`, {
          stationId: data.stationId,
          paramId: data.paramId,
          time: data.time,
          data: data.data
        });
        return;
      }else if(data.tag == 'lamp'){
        window.eventBus.$emit('lampRealDataComing', {
          id: data.deviceId,
          data: data.data,
        });
        return
      }else if(data.tag == 'env'){
        window.eventBus.$emit('envRealDataComing', {
          id: data.deviceId,
          data: data.data,
        });
        return
      }
      window.eventBus.$emit('paramRealDataComing', {
        stationId: data.stationId,
        paramId: data.paramId,
        time: data.time,
        data: data.data,
        extra: data.extra
      });
      vue.$store.commit(`cmd/setStationRealData`, {
        stationId: data.stationId,
        paramId: data.paramId,
        time: data.time,
        data: data.data,
        extra: data.extra
      });
    } else if (data.type == 'led') {
      if (data.cmdId == 'stateChange') { //led状态变化

      } else {
        vue.$store.commit('cmd/ledCmdReceived', {
          content: data.content,
          cmdId: data.cmdId,
          paramId: data.paramId,
          stationId: data.stationId,
          time: data.time
        });
      }
    } else if (data.type == 'ask') {
      if (data.content.appType == vue.$store.state.auth.appType && data.content.custId == vue.$store.state.auth.customerId) {
        vue.$store.commit('cmd/receiveCmdAskMessage', data);
      }
    } else if (data.type == 'lux') {
      // console.log('ws get fac lux data', data, vue.$store.state.auth.appType, vue.$store.state.auth.customerId);
      if (vue.$store.state.auth.appType == data.appType && vue.$store.state.auth.customerId == data.customerId) {
        // vue.$store.commit('cmd/setLuxContent', data);
        window.eventBus.$emit('luxRealDataComing', {
          id: data.luxId,
          lux: data.lux,
          temp: data.temp,
          hum: data.hum,
          // luxs: data.luxs,
          // temps: data.temps,
          // hums: data.hums,
          time: data.time
        });
      }
    } else if (data.type == 'step') {
      if (handlers[data.type]) {
        handlers[data.type](data);
      }
    } else if (data.type == 'reply') {
      window.eventBus.$emit('commandReplyComing', {
        code: data.code,
        cmdId: data.cmdId,
        status: data.status,
        message: data.message,
        content: data.content
      });
    }
  };
  ws.onclose = function () {
    if (closeTime == 0) {
      closeTime = new Date().getTime();
    }
    let now = new Date().getTime();
    let span = Math.floor((now - closeTime) / 1000);
    let day = Math.floor(span / 86400);
    let remain = span % 86400;
    let hour = Math.floor(remain / 3600);
    remain %= 3600;
    let mins = Math.floor(remain / 60);
    remain %= 60;
    let secs = Math.floor(remain);
    vue.$Notice.warning({
      title: `与服务器的连接已断开`,
      duration: 3,
      desc: `断开于${new Date(closeTime).format('yyyy-MM-dd HH:mm:ss')}，共计${day}天${hour}小时${mins}分钟${secs}秒`
    });
    clearInterval(heart);
    heart = null;
    setTimeout(startWebsocket, 3000, vue);
  };
}

const wsPlugin = {
  start() {},
  start1(vue) {
    startWebsocket(vue);
    window.onunload = function () {
      ws.close();
    }
  },
  sendHeart: function () {
    let time = new Date().getTime();
    // console.log('send heart', vue.$store.state)
    // let auth = vue.$store.state.auth || {};
    let token = localStorage.getItem('token');
    let appType = localStorage.getItem('apptype');
    let customerId = localStorage.getItem('custid');
    // if(!auth.appType || !auth.customerId)return;
    ws.send(JSON.stringify({
      cmd: 'heart',
      data: {
        time,
        appType: appType,
        customerId: customerId,
        token: token,
        // user: auth.user
      }
    }));
  },
  sendCmd(cmd, data) {
    ws.send(JSON.stringify({
      cmd: cmd,
      data: data
    }));
  },
  setHandler(type, hd) {
    handlers[type] = hd;
  },
  removeHander(type) {
    handlers[type] = null;
  }
}
export default wsPlugin