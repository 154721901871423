<template>
  <div class="loginSave">
    <img src="../assets/loginSave.png" alt="" style="width:310px;height:200px" />
    <p class="demo-loading">
      页面跳转中
    </p>
  </div>
</template>
<script>
// import Vue from 'vue';
import { mapState, mapGetters } from "vuex";
export default {
  name: "longinSave",
  components: {
  },
  computed: {
    ...mapState("auth", ["token", "user", "appType", "customerId", "appTypes", 'isNewMode']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  data: () => ({
    code: '',
    form: {
      name: '',
      pswd: ''
    }
  }),
  watch: {
  },
  mounted: function () {
    // nologin?code=eyJuYW1lIjogImFkbWluIiwicHN3ZCI6ICJodWZzYUplRioxNTk0In0=
    this.code = this.$router.history.current.query.code;
    let lang = localStorage.getItem("lighting_system_lang")
    if (lang != null) {
      this.langActive = lang;
    } else {
      localStorage.setItem("lighting_system_lang", 'zh')
      this.langActive = 'zh';
    }
    this.langAjax();
    this.validate();
  },
  methods: {
    encodeBase64(str) {
      return new Buffer(str).toString('base64');
    },
    decodeBase64(str) {
      return new Buffer(str, 'base64').toString();
    },
    langAjax() {
      this.$store.commit('auth/setLang', this.langActive);
    },
    validate: async function () {
      this.form = JSON.parse(this.decodeBase64(this.code))
      if (this.form.name.length == 0 || this.form.name.length > 20) {
        this.$Message.warning(this.showLang('logon.tell.name20'));
        return;
      }
      if (this.form.pswd.length == 0 || this.form.pswd.length > 20) {
        this.$Message.warning(this.showLang('logon.tell.pswd20'));
        return;
      }
      this.$store.commit("auth/setAppType", "");
      this.$store.dispatch("auth/loginNo", this.form).then((res) => {
        if (res.code == 0) {
          if (this.isNewMode) {
            this.$router.push('/main');
          } else if (this.appType == 'road') {
            this.$router.push('/index/light/road/home');
          } else if (this.appType == 'factory') {
            this.$router.push('/index/light/factory/home');
          } else if (this.appType == 'pole') {
            this.$router.push('/index/pole/home');
          } else if (this.appType == 'light') {
            this.$router.push('/index/light/single');
          } else {
            this.$router.push('/main');
          }
          return;
        } else {
          this.$Message.warning(this.showLang('logon.tell.name20'));
          return;
        }
      });
    },
    setUserInfo: function () {
      if (this.isPswd) {
        this.setCookie("account", this.form.name)
        this.setCookie("password", this.form.pswd)
      } else {
        this.setCookie("account", "")
        this.setCookie("password", "")
      }
    },
    setCookie: function (cName, value, expiredays) {
      let exdate = new Date()
      exdate.setDate(exdate.getDate() + expiredays)
      document.cookie = cName + '=' + decodeURIComponent(value) +
        ((expiredays == null) ? '' : ';expires=' + exdate.toGMTString())
    },
  },
  beforeDestroy() {
    // 一定要在组件销毁前移除事件监听，避免内存泄露
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    window.removeEventListener('unload', this.unloadHandeler);
  }
};
</script>
<style scoped>
.loginSave {
  width: 100%;
  height: 100%;
  background-color: #191c23;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.demo-loading{
  color: #fff;
  margin-top: 25px;
  font-size: 20px;
}
.demo-loading::after {
  color: #fff;
  content: "";
  animation: dots 2s infinite steps(3, start);
}
@keyframes dots {
  33.33% {
    content: ".";
  }
  66.67% {
    content: "..";
  }
  100% {
    content: "...";
  }
}
</style>