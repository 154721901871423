"use strict";

const dateUtil = {
  install: function () {
    Date.prototype.long2str = function(ts){
      if(ts <= 0)return '-';
      return new Date(ts).format('yyyy-MM-dd HH:mm:ss')
    },
    Date.prototype.todayStart = function(){
      return `${this.format('yyyy-MM-dd')} 00:00:00`;
    },
    Date.prototype.todayEnd = function(){
      return `${this.format('yyyy-MM-dd')} 23:59:59`;
    },
    Date.prototype.monthFirstDay = function () {
      return new Date(`${this.getFullYear()}-${this.getMonth()+1}-01`);
    }
    Date.prototype.monthLastDay = function () {
      let d = this.monthFirstDay();
      d.setMonth(d.getMonth() + 1);
      d.setDate(d.getDate() - 1);
      return d;
    }
    Date.prototype.firstDay = function () {
      return new Date(`${this.getFullYear()}-01-01`);
    }
    Date.prototype.lastDay = function () {
      let d = this.firstDay();
      d.setFullYear(d.getFullYear() + 1);
      d.setDate(d.getDate() - 1);
      return d;
    }
    Date.prototype.timing = function (minute) { //分钟化小时
      if (minute == 0 || !(minute*1>0)) return 0;
      return `${Math.round(minute / 60)}小时${Math.round(minute % 60)}分钟`;
    }
    Date.prototype.format = function (format) {
      // date = new Date(date);
      let year = this.getFullYear();
      // console.log('current year', year)
      if(year == 1970 || year == 1 || year == 2001)return '';
      let month = this.getMonth() + 1;
      let day = this.getDate();
      let hour = this.getHours();
      let min = this.getMinutes();
      let sec = this.getSeconds();
      let ms = this.getMilliseconds();
      return format.replace('yyyy', `${year}`.padStart(4, '0'))
        .replace("MM", `${month}`.padStart(2, '0'))
        .replace("dd", `${day}`.padStart(2, '0'))
        .replace("HH", `${hour}`.padStart(2, '0'))
        .replace("mm", `${min}`.padStart(2, '0'))
        .replace("ss", `${sec}`.padStart(2, '0'))
        .replace("fff", `${ms}`.padStart(3, '0'));
    };
    Date.prototype.sunRiseAndSet = function (lat, lng) {
      return {
        rise: this.sunriseSet(lat, lng, true, null).format('HH:mm'),
        set: this.sunriseSet(lat, lng, false, null).format('HH:mm')
      };
    }
    Date.prototype.sunrise = function (latitude, longitude, zenith) {
      return this.sunriseSet(latitude, longitude, true, zenith);
    }
    Date.prototype.sunset = function (latitude, longitude, zenith) {
      return this.sunriseSet(latitude, longitude, false, zenith);
    }
    Date.prototype.sunriseSet = function (latitude, longitude, sunrise, zenith) {
      if (!zenith) {
        zenith = 90.8333;
      }
      var hoursFromMeridian = longitude / Date.DEGREES_PER_HOUR,
        dayOfYear = this.getDayOfYear(),
        approxTimeOfEventInDays,
        sunMeanAnomaly,
        sunTrueLongitude,
        ascension,
        rightAscension,
        lQuadrant,
        raQuadrant,
        sinDec,
        cosDec,
        localHourAngle,
        localHour,
        localMeanTime,
        time;
      if (sunrise) {
        approxTimeOfEventInDays = dayOfYear + ((6 - hoursFromMeridian) / 24);
      } else {
        approxTimeOfEventInDays = dayOfYear + ((18.0 - hoursFromMeridian) / 24);
      }
      sunMeanAnomaly = (0.9856 * approxTimeOfEventInDays) - 3.289;
      sunTrueLongitude = sunMeanAnomaly + (1.916 * Math.sinDeg(sunMeanAnomaly)) + (0.020 * Math.sinDeg(2 * sunMeanAnomaly)) + 282.634;
      sunTrueLongitude = Math.mod(sunTrueLongitude, 360);
      ascension = 0.91764 * Math.tanDeg(sunTrueLongitude);
      rightAscension = 360 / (2 * Math.PI) * Math.atan(ascension);
      rightAscension = Math.mod(rightAscension, 360);
      lQuadrant = Math.floor(sunTrueLongitude / 90) * 90;
      raQuadrant = Math.floor(rightAscension / 90) * 90;
      rightAscension = rightAscension + (lQuadrant - raQuadrant);
      rightAscension /= Date.DEGREES_PER_HOUR;
      sinDec = 0.39782 * Math.sinDeg(sunTrueLongitude);
      cosDec = Math.cosDeg(Math.asinDeg(sinDec));
      let cosLocalHourAngle = ((Math.cosDeg(zenith)) - (sinDec * (Math.sinDeg(latitude)))) / (cosDec * (Math.cosDeg(latitude)));
      localHourAngle = Math.acosDeg(cosLocalHourAngle)
      if (sunrise) {
        localHourAngle = 360 - localHourAngle;
      }
      localHour = localHourAngle / Date.DEGREES_PER_HOUR;
      localMeanTime = localHour + rightAscension - (0.06571 * approxTimeOfEventInDays) - 6.622;
      time = localMeanTime - (longitude / Date.DEGREES_PER_HOUR);
      time = Math.mod(time, 24);
      var midnight = new Date(0);
      midnight.setUTCFullYear(this.getUTCFullYear());
      midnight.setUTCMonth(this.getUTCMonth());
      midnight.setUTCDate(this.getUTCDate());
      var milli = midnight.getTime() + (time * 60 * 60 * 1000);
      return new Date(milli);
    }
    Date.DEGREES_PER_HOUR = 360 / 24;
    Date.prototype.getDayOfYear = function () {
      var onejan = new Date(this.getFullYear(), 0, 1);
      return Math.ceil((this - onejan) / 86400000);
    }
    Math.degToRad = function (num) {
      return num * Math.PI / 180;
    }
    Math.radToDeg = function (radians) {
      return radians * 180.0 / Math.PI;
    }
    Math.sinDeg = function (deg) {
      return Math.sin(deg * 2.0 * Math.PI / 360.0);
    }
    Math.acosDeg = function (x) {
      return Math.acos(x) * 360.0 / (2 * Math.PI);
    }
    Math.asinDeg = function (x) {
      return Math.asin(x) * 360.0 / (2 * Math.PI);
    }
    Math.tanDeg = function (deg) {
      return Math.tan(deg * 2.0 * Math.PI / 360.0);
    }
    Math.cosDeg = function (deg) {
      return Math.cos(deg * 2.0 * Math.PI / 360.0);
    }
    Math.mod = function (a, b) {
      var result = a % b;
      if (result < 0) {
        result += b;
      }
      return result;
    }
  },
}
export default dateUtil;