<template>
  <DropdownItem v-if="canOp" :name="name" @click.native="clickBtn"><slot></slot></DropdownItem>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'AuthDropdownItem',
  components:{
  },
  props: {
    opCode: {
      type: String,
      default: ''
    },
    opCodes: {
      type: Array,
      default(){return []}
    },
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showModal: this.value,
      notAllowOps: ['c4', 'c5', 'c8'],
    }
  },
  computed:{
    ...mapState('auth', ['user', 'ops']),
    canOp: function(){
      if(this.user && this.user.isSuper)return true;
      if(this.notAllowOps.includes(this.opCode))return false;
      if(this.ops && this.ops.indexOf(this.opCode) != -1)return true;
      if(this.ops && this.ops.filter(p => this.opCodes.indexOf(p) != -1).length > 0)return true;
      return false;
    },
  },
  methods: {
    clickBtn () {
      this.$emit('click', {})
    },
  }
}
</script>
<style scoped>
</style>