import Vue from 'vue';

const params = {
  wsobj: null,
  need: false,
  callback: null,
}
function createXTXApp(){
  var o = new Object();
  o.ws_host = "ws://127.0.0.1:";
  o.ws_port_array = ["21051/xtxapp","4044","5044", "6044", "7044", "8044"]; 
  o.ws_port_use = 0;
  o.ws_obj = null;
  o.ws_heartbeat_id = 0;
  o.ws_queue_id = 0; // call_cmd_id
  o.ws_queue_list = {};  // call_cmd_id callback queue
  o.ws_queue_ctx = {};
  o.xtx_version = "";
  o.load_websocket = function() {
    if(!params.need){
      clearInterval(o.ws_heartbeat_id);
      return false;
    }
    if (o.ws_port_use >= o.ws_port_array.length) {
      o.ws_port_use = 0;
    }
    // try{
    //   if(o.ws_obj){
    //     o.ws_obj.close();
    //     o.ws_obj = null;
    //   }
    // }catch{
    //   //
    // }
    var ws_url = o.ws_host + o.ws_port_array[o.ws_port_use] + "/";
    try {
      o.ws_obj = new WebSocket(ws_url); 
    } catch (e) {
      console.log('xtx ws open failed', e)
      return false;
    }
    // console.log('xtx ws open success', o.ws_obj)
    o.ws_obj.onopen = function() { 
      // console.log('xtx ws open')
      clearInterval(o.ws_heartbeat_id);
      o.sendHeart();
      // o.callMethod("SOF_GetVersion", function(str){o.xtx_version = str.retVal;});
      o.ws_heartbeat_id = setInterval(function () {
        o.sendHeart();
          // o.callMethod("SOF_GetVersion", function(){});
      }, 10 * 1000);
      o.myGetUserList()
    };

    o.ws_obj.onerror = function() { 
      console.log('xtx ws error')
      try{
        o.ws_obj.close();
        o.ws_obj = null;
      }catch{
        //
      }
      // o.ws_port_use++;
      setTimeout(o.load_websocket, 3000)
      // o.load_websocket();
    };

    o.ws_obj.onclose = function() { 
      // console.log('xtx ws closed')
      // o.ws_port_use++;
      // o.load_websocket();
      setTimeout(o.load_websocket, 3000)
    }; 

    o.ws_obj.onmessage = function(evt) {
      let res = null;
      try{
        res = JSON.parse(evt.data);
      }catch{
        return;
      }
      if(!res)return;
      if(res.call_cmd_id == "heart"){
        // console.log('xtx heart ok', o.ws_queue_id)
      }else if(res.call_cmd_id == "getUserList"){
        // console.log('xtx getUserList ok:', res.retVal)
        if(params.callback){
          params.callback('user', res.retVal);
        }
      }else if(res.call_cmd_id == "onUsbkeyChange"){
        // console.log('xtx heart onUsbkeyChange')
      }else if(res.call_cmd_id == "OnUsbkeyRemove"){
        // console.log('xtx heart OnUsbkeyRemove')
        o.myGetUserList()
      }else if(res.call_cmd_id == "OnUsbkeyInsert"){
        // console.log('xtx heart OnUsbkeyInsert')
        o.myGetUserList()
      }else if(res.call_cmd_id == "login"){
        if(params.callback){
          params.callback('login', res.retVal);
        }
      }else{
        console.log('xtx other', res)
      }
    }; 

    o.sendHeart = function(){
      if(!params.need)return;
      o.ws_obj.send(JSON.stringify({
        cookie: "",
        xtx_func_name: "SOF_GetVersion",
        URL: "window.location.href",
        call_cmd_id: "heart"
      }))
      o.ws_queue_id++;
      // console.log('xtx heart send')
    }
    o.myGetUserList = function(){
      if(!params.need)return;
      o.ws_obj.send(JSON.stringify({
        cookie: "",
        xtx_func_name: "SOF_GetUserList",
        URL: "window.location.href",
        call_cmd_id: "getUserList"
      }))
      // console.log('xtx getUserList send')
    }
    o.login = function(key, pswd){
      if(!params.need)return;
      o.ws_obj.send(JSON.stringify({
        cookie: "",
        xtx_func_name: "SOF_Login",
        URL: "window.location.href",
        call_cmd_id: "login",
        param: [key, pswd],
        param_1: key,
        param_2: pswd
      }))
      // console.log('xtx login send')
    }
    return true;
  };
    
  if (!o.load_websocket()) {
    return null;
  }
  return o;
}
const XTXAPP = {
  load: function(callback){
    params.need = true;
    params.callback = callback;
    params.wsobj = createXTXApp();
  },
  login: function(key, pswd){
    params.wsobj.login(key, pswd);
  },
  unload: function(){
    params.need = false;
    if(params.wsobj.ws_obj){
      try{
        params.wsobj.ws_obj.close();
      }catch{
        //
      }
      params.wsobj.ws_obj = null;
    }
    params.callback = null;
    params.wsobj = null;
  },
  test: function(){
    console.log('xtx obj test');
  },
}
const MyXTXAPP = {
  install: function (Vue) {
    // console.log('xtx app installed');
    Object.defineProperties(Vue.prototype, {
      xtx: {
        get() {
          return XTXAPP;
        }
      },
    });
  }
}
Vue.use(MyXTXAPP)
export default MyXTXAPP;