<template>
<Modal v-model="showModal" :mask-closable="false" width="600px">
  <div slot="header">修改密码</div>
  <div style="text-align:right;max-height: 500px; overflow-y:auto">
    <Form ref="form" :model="form" class="form" :rules="rules" :label-width="80">
      <FormItem prop="old" label="旧密码">
        <Input ref="old" type="password" v-model="form.old" :maxlength="20" password placeholder="请输入旧密码"></Input>
      </FormItem>
      <FormItem prop="pswd" label="新密码">
        <Input ref="pswd" type="password" v-model="form.pswd" :maxlength="20" password placeholder="请输入新密码"></Input>
      </FormItem>
      <FormItem prop="conf" label="确认密码">
        <Input ref="conf" type="password" v-model="form.conf" :maxlength="20" password placeholder="请确认新密码"></Input>
      </FormItem>
      <FormItem label="" :label-width="0" style="text-align: center;">
        <ul>
          <li>密码规则：</li>
          <li>1、密码长度必须在8-20位之间</li>
          <li>2、必须包含大、小写字母、数字和特殊字符</li>
          <li>3、不能有连续相同或顺序、倒序排列的字符</li>
          <li>4、至少每半年修改一次密码</li>
        </ul>
      </FormItem>
    </Form>
  </div>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">取消</Button>
    <Button type="primary" :loading="loading" @click="ok">保存</Button>
  </div>
</Modal>
</template>
<script>
  export default {
    name: 'ModalModifyPswd',
    components:{
    },
    model: {
      prop: 'value',
      event: 'showChanged'
    },
    props: {
      value: {
        type: Boolean,
        default: false
      },
    },
    data () {
      return {
        showModal: this.value,
        loading: false,
        form: {
          user: this.user,
          old: '',
          pswd: '',
          conf: '',
        },
        rules: {
          old: [
            { required: true, message: '密码不能为空', trigger: 'blur' },
            { type: 'string', min: 2, max: 20, message: '密码长度必须 2 - 20 个字符', trigger: 'blur' }
          ],
          pswd: [
            { required: true, message: '密码不能为空', trigger: 'blur' },
            { type: 'string', min: 8, max: 20, message: '密码长度必须 8 - 20 个字符', trigger: 'blur' }
          ],
          conf: [
            { required: true, message: '密码不能为空', trigger: 'blur' },
            { type: 'string', min: 8, max: 20, message: '密码长度必须 8 - 20 个字符', trigger: 'blur' }
          ],
        },
      }
    },
    watch: {
      value(newVal) {
        this.showModal = newVal;
      },
      showModal(val){
        this.$emit('showChanged', val);
      }
    },
    methods: {
      ok: async function () {
        let result = await this.$refs.form.validate();
        if(!result){
          return;
        }
        if(this.form.pswd != this.form.conf){
          this.$Message.warning('两次密码输入不一致');
          return;
        }
        // this.form.conf = '';
        this.loading = true;
        let res = await this.$axios.post(`common/auth/Modify`, this.form);
        this.loading = false;
        if(res.code !== 0){
          // this.$Message.error(res.data);
          // this.hideLoading();
          return;
        }
        this.$Message.info('保存成功');
        setTimeout(() => {
          this.showModal = false;
        }, 800);
      },
      cancel () {
        this.showModal = false;
        this.$refs.form.resetFields();
      },
    }
  }
</script>
<style scoped>
li{
  text-align: left;
  padding-left: 100px;
  color:red;
}
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>