import Vue from 'vue'

const poleTypes = ['light', 'video', 'broad', 'speak', 'led', 'env', 'wifi', 'charge', 'manhole', 'garbage', 'phone'];
const tools = {
  getGroupPathName: function (groups, groupId) {
    let grps = groups.filter(p => p.id == groupId);
    if (grps.length == 0) return '';
    let parentName = tools.getGroupPathName(groups, grps[0].parentId);
    if (parentName == '') return grps[0].name;
    return `${tools.getGroupPathName(groups, grps[0].parentId)} -> ${grps[0].name}`;
  },
  getGroupDevices: function (devs, node) {
    if (node.type != 'group' && node.type != 'root') {
      devs.push(node);
    }
    if (!node.children) return;
    for (let item of node.children) {
      if (item.type == 'group' || item.type == 'root') {
        tools.getGroupDevices(devs, item);
      } else {
        devs.push(item);
      }
    }
  },
  initTreeNodes: function (node, groups) {
    let grps = groups.filter(item => item.parentId == node.id);
    for (let grp of grps) {
      let n = {
        id: grp.id,
        name: grp.name,
        type: 'group',
        expand: true,
        groupName: node.name,
        groupId: node.id,
        children: []
      };
      node.children.push(n);
      tools.initTreeNodes(n, groups);
    }
  },
  initSubNodes: function (node, groups, stations) {
    stations = stations || [];
    let grps = groups.filter(item => item.parentId == node.id);
    for (let grp of grps) {
      let n = {
        id: grp.id,
        name: grp.name,
        type: 'group',
        expand: true,
        groupName: node.name,
        groupId: node.id,
        children: []
      };
      node.children.push(n);
      tools.initSubNodes(n, groups, stations);
    }
    let ss = stations.filter(p => p.groupId == node.id);
    for (let s of ss) {
      let n = {
        type: 'Station',
        expand: true,
        groupName: node.name,
        groupId: node.id,
      };
      Object.assign(n, s);
      node.children.push(n);
    }
  },
  initPoleNodes: function (node, groups, stations, menu) {
    stations = stations || [];
    let grps = groups.filter(item => item.parentId == node.id);
    for (let grp of grps) {
      let n = {
        id: grp.id,
        name: grp.name,
        type: 'group',
        expand: true,
        groupName: node.name,
        groupId: node.id,
        children: []
      };
      node.children.push(n);
      tools.initPoleNodes(n, groups, stations, menu);
    }
    let ss = stations.filter(p => p.groupId == node.id);
    if (menu != 'gis') {
      ss = ss.filter(p => p[menu] > 0);
    }
    for (let s of ss) {
      let n = {
        type: 'Station',
        expand: true,
        groupName: node.name,
        groupId: node.id,
      };
      Object.assign(n, s);
      node.children.push(n);
    }
  },
  getSelectNodeById: function (node, nodeId, type) {
    for (let n of node) {
      if (n.id == nodeId && n.type == type) return n;
      if (!n.children) continue;
      let result = tools.getSelectNodeById(n.children, nodeId, type);
      if (result) return result;
    }
    return null;
  },
  removeNodeById: function (nodes, nodeId, type) {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id == nodeId && nodes[i].type == type) {
        nodes.splice(i, 1);
        return;
      }
      if (!nodes[i].children) continue;
      tools.removeNodeById(nodes[i].children, nodeId, type);
    }
  },
  setNodeById: function (nodes, nodeId, type, online, alarm) {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id == nodeId && nodes[i].type == type) {
        nodes[i].online = online;
        nodes[i].alarm = alarm;
        return;
      }
      if (!nodes[i].children) continue;
      tools.removeNodeById(nodes[i].children, nodeId, type);
    }
  },
  getSelectedGroups: function (grps, node) {
    if (node.type != 'root' && node.type != 'group') return;
    grps.push({
      id: node.id,
      name: node.name,
      type: 'group',
      groupName: node.groupName,
      groupId: node.groupId
    });
    if (!node.children) return;
    for (let g of node.children) {
      if (g.type == 'group') {
        tools.getSelectedGroups(grps, g);
      }
    }
  },
  findPoleTreeNode: function (nodes, id, isLeaf) {
    // console.log('find pole tree node', nodes);
    for (let n of nodes) {
      if (n.id == id && n.isLeaf == isLeaf) return n;
      if (!n.children) continue;
      if (n.isLeaf) continue;
      let result = tools.findPoleTreeNode(n.children, id, isLeaf);
      if (result) return result;
    }
    return null;
  },
  getPoleSelectedGroups: function (grps, node) {
    if (!node || node.isLeaf) return;
    // console.log('get pole sel node', node)
    grps.push({
      id: node.id,
      name: node.name,
      groupId: node.groupId,
      groupName: node.groupName
    });
    if (!node.children) return;
    for (let g of node.children) {
      if (!g.isLeaf) {
        tools.getPoleSelectedGroups(grps, g);
      }
    }
  },
  getPoleSelectedDevices: function (devs, node) {
    if (node.isLeaf) {
      devs.push({
        id: node.id,
        name: node.name,
        groupId: node.groupId,
        groupName: node.groupName
      });
    } else {
      if (!node.children) return;
      for (let g of node.children) {
        tools.getPoleSelectedDevices(devs, g);
      }
    }
  },
  AddGroup: function (nodes, grp) {
    for (let n of nodes) {
      if (n.type != 'root' && n.type != 'group') return;
      if (n.id == grp.parentId) {
        n.children.push({
          id: grp.id,
          name: grp.name,
          type: 'group',
          expand: true,
          groupName: n.name,
          groupId: n.id,
          children: []
        })
        return;
      } else {
        tools.AddGroup(n.children, grp);
      }
    }
  },
  editGroup: function (nodes, grp) {
    tools.removeNodeById(nodes, grp.id, grp.type);
    tools.AddGroup(nodes, grp);
  },
  refershTreeDevice: function (nodes, devs, types) {
    for (let node of nodes) {
      if (node.type != 'root' && node.type != 'group') continue;
      let ds = devs.filter(p => p.groupId == node.id && types.indexOf(p.type) != -1);
      for (let d of ds) {
        let curs = node.children.filter(p => p.id == d.id);
        if (curs.length == 0) {
          node.children.push(d);
        } else {
          for (let key in curs[0]) {
            curs[0][key] = d[key];
          }
        }
      }
      tools.refershTreeDevice(node.children, devs, types);
    }
  },
  checkPoleTreeType: function (item, type) {
    if (poleTypes.indexOf(type) == -1) return true;
    if (item[type] > 0) return true;
    return false;
  },
  refershPoleTreeDevice: function (nodes, devs, type) {
    for (let node of nodes) {
      if (node.type != 'root' && node.type != 'group') continue;
      let ds = devs.filter(p => p.groupId == node.id && this.checkPoleTreeType(p, type));
      for (let d of ds) {
        let curs = node.children.filter(p => p.id == d.id);
        if (curs.length == 0) {
          node.children.push(d);
        } else {
          for (let key in curs[0]) {
            curs[0][key] = d[key];
          }
        }
      }
      tools.refershPoleTreeDevice(node.children, devs, type);
    }
  },
  clearEmptyGroup: function (nodes) {
    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i];
      if (node.type !== 'group' && node.type !== 'root') continue;
      if (node.children.length == 0) {
        nodes.splice(i, 1);
        i--;
      } else {
        this.clearEmptyGroup(node.children);
      }
    }
  },
  setDeviceOnline: function (nodes, id, online, alarm, running, time) {
    if (!time) return;
    for (let node of nodes) {
      if (node.type != 'root' && node.type != 'group') {
        if (node.id == id) {
          node.online = online;
          node.alarm = alarm;
          node.running = running;
          node.refreshTime = new Date(time).format('yyyy-MM-dd HH:mm:ss');
          // console.log('set tree node state', node.name, online, alarm, running, time)
        }
      } else {
        tools.setDeviceOnline(node.children || [], id, online, alarm, running, time);
      }
    }
  },
  setDeviceState: function (nodes, id, online, alarm, running) {
    if (!nodes) return;
    for (let node of nodes) {
      if (node.type != 'root' && node.type != 'group') {
        if (node.id == id) {
          node.online = online;
          node.alarm = alarm;
          node.running = running;
        }
      } else {
        tools.setDeviceState(node.children, id, online, alarm, running);
      }
    }
  },
  setDeviceLightOn: function (nodes, id, on) {
    for (let node of nodes) {
      if (node.type != 'root' && node.type != 'group') {
        if (node.id == id) {
          node.light = on;
        }
      } else {
        tools.setDeviceLightOn(node.children, id, on);
      }
    }
  },
  clearTreeDevices: function (nodes) {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].type == 'root' || nodes[i].type == 'group') {
        tools.clearTreeDevices(nodes[i].children);
      } else {
        nodes.splice(i, 1);
        i--;
      }
    }
  },
  getTopGroups: function (list) {
    let tops = [];
    for (let g of list) {
      let ps = list.filter(p => p.id == g.parentId);
      if (ps.length == 0) {
        tops.push(g);
      }
    }
    return tops;
  },
}
const state = {
  rootTitle: '', // 树根节点名称
  groups: [], // 所有分组信息
  groupIds: [], // 所有分组ID数据，用于取设备数据
  devices: [], // 所有设备信息，格式：{id: '设备ID', type: '产品类型编码', code: '设备编码', name: '设备名称', online: true, alarm: true}
  treeData: [], // 成品树结构数据，格式：{id: 0, type: 'root', name: 'XX树', expand: true, children: []}，type值：root、根节点，group、分组节点，其他、各产品类型节点
  selectedNode: { nodeId: 0, isLeaf: false, type: 'root' }, // 当前选中节点
  poleSelectedNode: { id: '0', isLeaf: false },
  selectedDevices: [], // 当前选中节点下的所有设备
  selectedGroups: [], // 当前选中节点下所有的分组
  filterList: [], //树查询结果列表
  showDeviceTypes: [], //树显示的设备类型列表
  backImages: [], //所有分组背景图片列表
  devPoses: [], //所有元素分布列表
  groupLoaded: false, //分组数据是否加载完毕
  filterTreeNode: null, //通过查询找到的节点
  stations: [], //所有站点数据
  stationType: 0, //缓存每次查询站点的类型
  poleMenu: '', //缓存每次显示的灯杆站点目录名称
  lightConfigChanged: 0, //单灯配置数据变化，需要刷新数据时监听
  paramStates: [], //站点属性状态
  paramStateChanged: 0, //站点属性状态变化
  stationStates: [], //站点状态
  topIds: [], //顶级分组ID列表
  stationAlarmChanged: 0, //站点报警是否有变化
  lightPowerError: [],//单灯供电异常
  treeDataUrls: {
    home: 'pole/tree/QueryAll',
    pole: 'pole/tree/QueryAll',
    led: 'pole/tree/QueryLed',
    broad: 'pole/tree/QueryBroad',
    charge: 'pole/tree/QueryCharge',
    env: 'pole/tree/QueryEnvironment',
    light: 'pole/tree/QueryLamp',
    lock: 'pole/tree/QueryLock',
    phone: 'pole/tree/QueryPhone',
    power: 'pole/tree/QueryPower',
    speak: 'pole/tree/QuerySpeak',
    wifi: 'pole/tree/QueryWifi',
  },
  groupTreeSelectedNode: { id: 0 },
}
const mutations = {
  selectGroupTreeNode: function (state, node) {
    state.groupTreeSelectedNode = node;
  },
  setDeviceState: function (state, args) {
    // if(args.id == 682){
    //   console.log("get station 682 msg1:", args)
    // }
    if (!args.isParam) {
      tools.setDeviceState(state.treeData, args.id, args.online, args.alarm, args.running);
    }
  },
  setParamState: function (state, args) {
    // console.log('get state msg 1', args)
    if (args.isParam) {
      let ps = state.paramStates.filter(p => p.id == args.id);
      if (ps.length == 0) {
        state.paramStates.push({ id: args.id, online: args.online, alarm: args.alarm, running: args.running })
        state.stationAlarmChanged++;
      } else {
        let p = ps[0];
        p.time = args.time;
        if (p.online != args.online || p.alarm != args.alarm || p.running != args.running) {
          p.online = args.online;
          p.running = args.running;
          state.paramStateChanged++;
          if (p.alarm != args.alarm) {
            p.alarm = args.alarm;
            state.stationAlarmChanged++;
          }
        }
      }
    } else {
      let ss = state.stationStates.filter(p => p.id == args.id);
      if (ss.length == 0) {
        state.stationStates.push({ id: args.id, online: args.online, alarm: args.alarm, running: args.running });
        tools.setDeviceOnline(state.treeData, args.id, args.online, args.alarm, args.running, args.time);
      } else {
        let s = ss[0];
        s.time = args.time;
        s.online = args.online;
        s.alarm = args.alarm;
        s.running = args.running;
        tools.setDeviceOnline(state.treeData, s.id, s.online, s.alarm, s.running, s.time);
      }
    }
  },
  lightDataEdited: function (state) {
    state.lightConfigChanged++;
  },
  setStationStatus: function (state, params) {
    state.selectedNode.status = params.status;
    state.selectedNode.startTime = params.startTime;
    state.selectedNode.stopTime = params.stopTime;
  },
  setBackImages: function (state, list) {
    state.backImages = list;
  },
  backImageSaved: function (state, params) {
    let add = true;
    for (let i = 0; i < state.backImages.length; i++) {
      if (state.backImages[i].id == params.id) {
        Vue.set(state.backImages, i, params);
        add = false;
      }
    }
    if (add) {
      state.backImages.push(params);
    }
  },
  setDevPoses: function (state, list) {
    state.devPoses = list;
  },
  devPosSaved: function (state, params) {
    let add = true;
    for (let i = 0; i < state.devPoses.length; i++) {
      if (state.devPoses[i].id == params.id) {
        Vue.set(state.devPoses, i, params);
        add = false;
      }
    }
    if (add) {
      state.devPoses.push(params);
    }
  },
  setDeviceLightOn: function (state, { id, on }) {
    tools.setDeviceLightOn(state.treeData, id, on);
  },
  setDeviceOnline: function (state, { id, online, alarm, running, time }) {
    tools.setDeviceOnline(state.treeData, id, online, alarm, running, time);
  },
  setDeviceTypes: function (state, types) {
    state.showDeviceTypes = types;
    tools.clearTreeDevices(state.treeData);
    tools.refershTreeDevice(state.treeData, state.devices, state.showDeviceTypes);
    let devs = [];
    tools.getGroupDevices(devs, state.selectedNode);
    state.selectedDevices = devs;
    let grps = [];
    tools.getSelectedGroups(grps, state.selectedNode);
    state.selectedGroups = grps;
  },
  filterTree: function (state, filter) {
    let devs = state.devices.filter(item => item.name.indexOf(filter.value) != -1);
    let grps = state.groups.filter(item => item.name.indexOf(filter.value) != -1);
    let device = filter.deviceAll.filter(item => item.code.indexOf(filter.value) != -1);
    if (devs.length > 0 || grps.length > 0 || device.length > 0) {
      grps.push(...devs, ...device);
      state.filterList = grps;
      // console.log(state.filterList)
    } else {
      state.filterList = [];
    }
  },
  initRootTitle: function (state, title) {
    state.rootTitle = title;
  },
  initGroups: function (state, groups) {
    state.groupLoaded = false;
    state.groups = groups;
    state.groupIds = groups.map(p => {
      return p.id
    });
    setTimeout(() => {
      state.groupLoaded = true;
    }, 50);
  },
  clearDevices: function (state) {
    state.devices = [];
    tools.clearTreeDevices(state.treeData);
  },
  addDevices: function (state, devs) {
    state.devices.push(...devs);
    tools.refershTreeDevice(state.treeData, devs, state.showDeviceTypes);
    let devs1 = [];
    tools.getGroupDevices(devs1, state.selectedNode);
    state.selectedDevices = devs1;
  },
  refreshDevice: function (state, dev) {
    let ds = state.devices.filter(p => p.id == dev.id);
    if (ds.length == 0) {
      state.devices.push(dev);
    } else {
      for (let key in ds[0]) {
        ds[0][key] = dev[key];
      }
    }
    tools.removeNodeById(state.treeData, dev.id, dev.type);
    tools.refershTreeDevice(state.treeData, [dev], state.showDeviceTypes);
    let devs = [];
    tools.getGroupDevices(devs, state.selectedNode);
    state.selectedDevices = devs;
  },
  initTreeData: function (state) {
    let treeData = [{
      id: 0,
      name: state.rootTitle,
      type: 'root',
      expand: true,
      children: []
    }];
    let tops = tools.getTopGroups(state.groups);
    for (let grp of tops) {
      let n = {
        id: grp.id,
        name: grp.name,
        type: 'group',
        expand: true,
        groupName: state.rootTitle,
        groupId: 0,
        children: []
      };
      treeData[0].children.push(n);
      tools.initTreeNodes(n, state.groups);
    }
    state.treeData = treeData;
    state.selectedNode = treeData[0];
    tools.refershTreeDevice(state.treeData, state.devices, state.showDeviceTypes);
    let devs = [];
    tools.getGroupDevices(devs, state.selectedNode);
    state.selectedDevices = devs;
    let grps = [];
    tools.getSelectedGroups(grps, state.selectedNode);
    state.selectedGroups = grps;
  },
  setPoleDeviceTypes: function (state, poleType) {
    // console.log('set pole device types', this)
    this.commit('group/resetPoleTree', {
      groups: state.groups,
      stations: state.stations,
      rootTitle: state.rootTitle,
      menu: poleType,
    });
  },
  setNodeStatusById: function (state, {
    nodeId,
    online,
    alarm
  }) {
    tools.setNodeStatusById(state.treeData, nodeId, online, alarm);
  },
  setPoleTreeData: function (state, params) {
    state.treeData = params;
  },
  selectPoleTreeNode: function (state, { id, isLeaf }) {
    let node = tools.findPoleTreeNode(state.treeData, id, isLeaf);
    state.poleSelectedNode = node;// {isLeaf, id};
    let grps = [];
    tools.getPoleSelectedGroups(grps, state.poleSelectedNode);
    state.selectedGroups = grps;
    let devs = [];
    tools.getPoleSelectedDevices(devs, state.poleSelectedNode);
    state.selectedDevices = devs;
  },
  selectNodeByIdNew: function (state, { node }) {
    state.groupTreeSelectedNode = node;
  },
  selectNodeById: function (state, { nodeId, type }) {
    let node = tools.getSelectNodeById(state.treeData, nodeId, type);
    if (node) {
      if (node.type != 'root' && node.type != 'group') {
        state.selectedNode = node;
        state.selectedDevices = [node];
        state.selectedGroups = [];
      } else {
        state.selectedNode = node;
        let devs = [];
        tools.getGroupDevices(devs, node);
        state.selectedDevices = devs;
        let grps = [];
        tools.getSelectedGroups(grps, state.selectedNode);
        state.selectedGroups = grps;
      }
      state.filterTreeNode = node;
    }
  },
  selectNode: function (state, node) {
    // console.log('tree filter node click change store', state.selectedNode.id, node.id)
    if (node.type != 'root' && node.type != 'group') {
      state.selectedNode = node;
      state.selectedDevices = [node];
      state.selectedGroups = [];
    } else {
      state.selectedNode = node;
      let devs = [];
      tools.getGroupDevices(devs, node);
      state.selectedDevices = devs;
      let grps = [];
      tools.getSelectedGroups(grps, state.selectedNode);
      state.selectedGroups = grps;
    }
  },
  removeNode: function (state, { nodeId, type }) {
    tools.removeNodeById(state.treeData, nodeId, type);
    let grps = [];
    tools.getSelectedGroups(grps, state.selectedNode);
    state.selectedGroups = grps;
  },
  groupSaved: function (state, { isAdd, group }) {
    if (isAdd) {
      state.groups.push(group);
      tools.AddGroup(state.treeData, group);
    } else {
      for (let i = 0; i < state.groups.length; i++) {
        if (state.groups[i].id == group.id) {
          Vue.set(state.groups, i, group);
          break;
        }
      }
    }
  },
  groupDeleted: function (state, groupId) {
    for (let i = 0; i < state.groups.length; i++) {
      if (state.groups[i].id == groupId) {
        state.groups.splice(i, 1);
      }
    }
  },
  resetPoleTree: function (state, params) {
    state.groups = params.groups;
    state.stations = params.stations;
    state.rootTitle = params.rootTitle;
    state.poleMenu = params.menu;
    state.stationType = 3;
    let treeData = [{
      id: 0,
      name: params.rootTitle,
      type: 'root',
      expand: true,
      children: []
    }];
    let tops = tools.getTopGroups(state.groups);
    let tids = [];
    for (let grp of tops) {
      tids.push(grp.id);
      let n = {
        id: grp.id,
        name: grp.name,
        type: 'group',
        expand: true,
        groupName: params.rootTitle,
        groupId: 0,
        children: []
      };
      treeData[0].children.push(n);
      tools.initPoleNodes(n, state.groups, state.stations, state.poleMenu);
    }
    state.topIds = tids;
    state.treeData = treeData;
    if (state.poleMenu != 'pole') {
      for (let i = 0; i < 10; i++) {
        tools.clearEmptyGroup(state.treeData);
      }
    }
    // if(!state.selectedNode){
    //   state.selectedNode = treeData[0];
    // }else{

    // }
    // state.selectedNode = treeData[0];
    let devs = [];
    tools.getGroupDevices(devs, state.selectedNode);
    state.selectedDevices = devs;
    let grps = [];
    tools.getSelectedGroups(grps, state.selectedNode);
    state.selectedGroups = grps;
    // console.log('init pole tree', params, devs, grps)
  },
  resetTreeData: function (state, params) {
    state.groups = params.groups;
    state.stations = params.stations;
    state.rootTitle = params.rootTitle;
    state.stationType = params.type;
    let treeData = [{
      id: 0,
      name: params.rootTitle,
      type: 'root',
      expand: true,
      children: []
    }];
    let tops = tools.getTopGroups(state.groups);
    let tids = [];
    for (let grp of tops) {
      tids.push(grp.id);
      let n = {
        id: grp.id,
        name: grp.name,
        type: 'group',
        expand: true,
        groupName: params.rootTitle,
        groupId: 0,
        children: []
      };
      treeData[0].children.push(n);
      tools.initSubNodes(n, state.groups, state.stations);
    }
    state.topIds = tids;
    state.treeData = treeData;
    // if(!state.selectedNode){
    //   state.selectedNode = treeData[0];
    // }else{

    // }
    // state.selectedNode = treeData[0];
    let devs = [];
    tools.getGroupDevices(devs, state.selectedNode);
    state.selectedDevices = devs;
    let grps = [];
    tools.getSelectedGroups(grps, state.selectedNode);
    state.selectedGroups = grps;
  },
  groupSavedNew: function (state, { isAdd, group }) {
    if (isAdd) {
      state.groups.push(group);
      tools.AddGroup(state.treeData, group);
    } else {
      for (let i = 0; i < state.groups.length; i++) {
        if (state.groups[i].id == group.id) {
          Vue.set(state.groups, i, group);
          break;
        }
      }
    }
  },
  setRootTitle: function (state, name) {
    if (state.treeData.length > 0) {
      state.treeData[0].name = name;
    }
  },
  addChangheStationToTreeData: function (state, changheTree) {
    let i = -1, j = -1;
    for (let changheSite of changheTree) {
      let root = state.treeData[0];
      if (changheSite.groupId > 0) {
        root = tools.getSelectNodeById(state.treeData, changheSite.groupId, 'group');
      }
      // console.log('get changhe root node', root);
      let n = {
        id: i--,
        name: `长和：${changheSite.name}`,
        type: 'root',
        isChanghe: true,
        expand: true,
        groupName: state.rootTitle,
        groupId: 0,
        children: []
      };
      j = -1;
      for (let changheGroup of changheSite.groups) {
        let nGroup = {
          id: j--,
          name: `${changheGroup.name}`,
          type: 'group',
          isChanghe: true,
          expand: true,
          groupName: n.name,
          groupId: -1,
          children: []
        };
        n.children.push(nGroup);
        var stations = changheSite.stations.filter(p => p.group == changheGroup.name);
        for (let station of stations) {
          let nStation = {
            id: station.id,
            accountId: changheSite.id,
            name: `${station.name}`,
            type: 'Station',
            isChanghe: true,
            expand: true,
            groupName: nGroup.name,
            groupId: -2,
            online: station.online,
            alarm: station.alarm,
            running: station.running,
            lat: station.lat,
            lng: station.lng,
            lightCount: station.lightCount,
            lightAlarm: station.lightAlarm
          };
          nGroup.children.push(nStation);
        }
      }
      if (root) root.children.push(n);
    }
  },
}
const actions = {
  getLightPowerError(context) {
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/device/light/QueryLightPowerError`, {}).then(res => {
        if (res.code != 0) {
          resolve(res);
          return;
        }
        context.state.lightPowerError = res.data;
        resolve(res);
      });
    })
  },
  getPoleTreeData: function (context, menu) {
    // console.log('get pole tree data', context.state.treeDataUrls, menu)
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/${context.state.treeDataUrls[menu]}`, {}).then(res => {
        if (res.code != 0) {
          resolve(res);
          return;
        }
        context.commit('setPoleTreeData', [res.data])
        let selectnode = { id: context.state.poleSelectNode ? context.state.poleSelectNode.id : '0', isLeaf: context.state.poleSelectNode ? context.state.poleSelectNode.isLeaf || false : false };
        context.commit('selectPoleTreeNode', { id: selectnode.id, isLeaf: selectnode.isLeaf });
        // console.log('get pole tree data', selectnode, context.state.poleSelectNode)
        resolve(res);
      });
    })
  },
  savePoleGroup: function (context, { form }) {
    return new Promise(resolve => {
      Vue.$axios.post(`sys/auth/SaveGroupOld`, form).then(res => {
        if (res.code == 0) {
          context.dispatch('getPoleTreeData', 'pole')
        }
        resolve(res);
      });
    });
  },
  deletePoleGroup: function (context, groupId) {
    return new Promise(resolve => {
      Vue.$axios.post(`sys/auth/DeleteGroupOld`, { id: groupId }).then(res => {
        if (res.code == 0) {
          context.dispatch('getPoleTreeData', 'pole')
        }
        resolve(res);
      });
    });
  },
  getGroupAndStations: function (context, type) {
    return new Promise(resolve => {
      let ps = [];
      ps.push(Vue.$axios.post(`//${Vue.domains.trans}/station/config/QueryGroup`, {}));
      if (type >= 0) {
        ps.push(Vue.$axios.post(`//${Vue.domains.trans}/station/config/QueryStation`, { type }));
      }
      Promise.all(ps).then(res => {
        // console.log('get group and stations result', context.rootState.auth.config);
        let treeD = {
          type: type,
          rootTitle: context.rootState.auth.config.appName,
          groups: res[0].code == 0 ? res[0].data : [],
          stations: res.length > 1 ? (res[1].code == 0 ? res[1].data : []) : []
        };
        context.commit('resetTreeData', treeD);
        context.commit('selectNodeById', { type: context.state.selectedNode.type ? context.state.selectedNode.type : 'root', nodeId: context.state.selectedNode.type ? context.state.selectedNode.id : 0 });
        // console.log('get promise all loaded', context.state);
        resolve(res);
        Vue.$axios.post(`//${Vue.domains.trans}/station/config/QueryChangheGroupAndStation`, {}).then(resChangehe => {
          // console.log('get changhe stations', resChangehe);
          if (resChangehe.code == 0 && resChangehe.data.length > 0) {
            context.commit('addChangheStationToTreeData', resChangehe.data)
          }
        });
      })
    });
  },
  getGroupAndPoles: function (context, menu) {
    return new Promise(resolve => {
      let ps = [];
      ps.push(Vue.$axios.post(`//${Vue.domains.trans}/station/config/QueryGroup`, {}));
      ps.push(Vue.$axios.post(`//${Vue.domains.trans}/station/config/QueryPoleStation`, { type: 3 }));
      Promise.all(ps).then(res => {
        // console.log('get promise all data', res, context);
        context.commit('resetPoleTree', {
          menu: menu,
          rootTitle: context.rootState.auth.config.name,
          groups: res[0].code == 0 ? res[0].data : [],
          stations: res[1].code == 0 ? res[1].data : []
        });
        context.commit('selectNodeById', { type: context.state.selectedNode.type ? context.state.selectedNode.type : 'root', nodeId: context.state.selectedNode.type ? context.state.selectedNode.id : 0 });
        // console.log('get promise all loaded', context.state);
        resolve({ code: 0 });
      })
    });
  },
  getDevPoses: function (context) {
    return new Promise(resolve => {
      Vue.$axios.get(`//${Vue.domains.trans}/group/pos`).then(res => {
        if (res.code == 0) {
          context.commit('setDevPoses', res.data);
        }
        resolve(res);
      });
    });
  },
  setDevPos: function (context, params) {
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/group/pos`, params).then(res => {
        if (res.code == 0) {
          context.commit('devPosSaved', res.data);
        }
        resolve(res);
      });
    });
  },
  getGroupImages: function (context) {
    return new Promise(resolve => {
      Vue.$axios.get(`//${Vue.domains.trans}/group/background`).then(res => {
        if (res.code == 0) {
          context.commit('setBackImages', res.data);
        }
        resolve(res);
      });
    });
  },
  setGroupImage: function (context, params) {
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/group/background`, params).then(res => {
        if (res.code == 0) {
          context.commit('backImageSaved', res.data);
        }
        resolve(res);
      });
    });
  },
  deleteGroup: function (context, groupId) {
    return new Promise(resolve => {
      Vue.$axios.post(`sys/auth/DeleteGroupOld`, { id: groupId }).then(res => {
        if (res.code == 0) {
          context.commit('groupDeleted', groupId);
          context.commit('removeNode', {
            nodeId: groupId,
            type: 'group'
          });
        }
        resolve(res);
      });
    });
  },
  saveGroupNew: function (context, { form }) {
    return new Promise(resolve => {
      Vue.$axios.post(`sys/auth/SaveGroupOld`, form).then(res => {
        if (res.code == 0) {
          context.dispatch('getGroupAndStations', context.state.stationType)
        }
        resolve(res);
      });
    });
  },
  saveStation: function (context, { form }) {
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/station/config/SaveStation`, form).then(res => {
        if (res.code == 0) {
          // console.log('context.state.stationType', context.state.stationType)
          if (context.state.stationType == 3) {
            context.dispatch('getGroupAndPoles', context.state.poleMenu)
          } else {
            context.dispatch('getGroupAndStations', context.state.stationType)
          }
        }
        resolve(res);
      });
    });
  },
  deleteStation: function (context, stationIds) {
    return new Promise(resolve => {
      Vue.$axios.post(`//${Vue.domains.trans}/station/config/DeleteStation`, { list: stationIds }).then(res => {
        if (res.code === 0) {
          if (context.state.stationType == 3) {
            context.dispatch('getGroupAndPoles', context.state.poleMenu)
          } else {
            context.dispatch('getGroupAndStations', context.state.stationType)
          }
        }
        resolve(res);
      });
    });
  },
}
const getters = {
  groupPathName: function (state) {
    return function (groupId) {
      return tools.getGroupPathName(state.groups, groupId);
    }
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};